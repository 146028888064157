import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="NFT Marketplace App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/nft-marketplace-mobile-app/"
    metaDescription="Looking to design your own NFT marketplace app? NFTrade, our mobile app template, is here to kickstart your project. Sign up for Uizard Pro today!"
    description="
    h2:Introducing NFTrade: Our NFT Marketplace mobile app design template
    <br/>
    Looking to design your own NFT marketplace app to capitalize on the growing size of the NFT market? NFTrade, one of our new <a:https://uizard.io/templates/>UI templates</a>, might be exactly what you’re looking for. Our templates come with all the must-have screens and elements baked in, meaning you can update them to suit your branding and hand them off to a developer in next to no time.
    <br/>
    h3:Freshly mint your own digital product design
    <br/>
    That's right, our designs come with all the screens, components, and user journeys your <a:https://uizard.io/mockups/>UI mockup</a> might need, but changing things up to suit your project is super easy. You can add new screens, change logos, and update color schemes; with Uizard, you can even import the theme of another website to really make your vision come to life!
    <br/>
    h3:Work solo or with your team
    <br/>
    Whether you are a founder working alone, or a digital marketer working in a big team, Uizard is streamlined to help you get the most out of your project. To invite team members, you can simply share the link to your project and work together in real-time on your design. Collaboration has never been so easy.
    "
    pages={[
      "A stunning home page screen with ice-blue accents",
      "An NFT wallet connection screen for new users",
      "NFT browsing screen with images and bid CTAs",
      "NFT product display pages with NFT product and auction details",
    ]}
    projectCode="3ozKzq98GMC1opRY9885"
    img1={data.image1.childImageSharp}
    img1alt="nft marketplace mobile app design overview"
    img2={data.image2.childImageSharp}
    img2alt="nft marketplace mobile app design home screen"
    img3={data.image3.childImageSharp}
    img3alt="nft marketplace mobile app design product screen"
    img4={data.image4.childImageSharp}
    img4alt="nft marketplace mobile app design user profile"
    img5={data.image5.childImageSharp}
    img5alt="nft marketplace mobile app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/nft-marketplace-mobile-app/nft-market-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/nft-marketplace-mobile-app/nft-market-mobile-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/nft-marketplace-mobile-app/nft-market-mobile-product.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/nft-marketplace-mobile-app/nft-market-mobile-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/nft-marketplace-mobile-app/nft-market-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
